import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import img1 from '../../images/inuvio/controlling1.png';
import img2 from '../../images/inuvio/controlling2.png';
import Carousel from "react-bootstrap/Carousel"

import "./Controling.scss"

const Controling = () => {
    return (
        <section id="controling" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="controling.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot">
                            <FormattedMessage id="controling.text" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="row middle-content">
                            <div className="col-lg-6">
                                <ul className="custom-list">
                                    <li><FormattedMessage id="controling.points.one"/></li>
                                    <li><FormattedMessage id="controling.points.two"/></li>
                                    <li><FormattedMessage id="controling.points.three"/></li>
                                    <li><FormattedMessage id="controling.points.four"/></li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul className="custom-list">
                                    <li><FormattedMessage id="controling.points.five"/></li>
                                    <li><FormattedMessage id="controling.points.six"/></li>
                                    <li><FormattedMessage id="controling.points.seven"/></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center contact-us">
                        <ul
                            style={{
                            display: `flex`,
                            flexWrap: `wrap`,
                            justifyContent: `space-between`,
                            listStyle: `none`,
                            padding: 0,
                            }}>
                            <li>
                                <Link to="/helios_vyroba" className="navlink-left">
                                    <i className="fa fa-arrow-left nav-left-icon" aria-hidden="true"></i>
                                    <FormattedMessage id="vyroba.title"/>
                                </Link>
                            </li>
                            <li>
                                <p>Zaujali jsme vás? <Link to="/#contact-section">Kontaktujte nás</Link>.</p>
                            </li>
                            <li>
                                <Link to="/helios_mzdy" className="navlink-right">
                                    <FormattedMessage id="mzdy.title"/>
                                    <i className="fa fa-arrow-right nav-right-icon" aria-hidden="true"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="laptop">
                    <div className="laptop-screen">
                        <Carousel fade nextIcon={<span><i className="fa fa-caret-right fa-2x calouser-icon"></i></span>}
                            prevIcon={<span><i className="fa fa-caret-left fa-2x calouser-icon" aria-hidden="true"></i></span>} 
                            className="reference-carousel slide">
                            <Carousel.Item interval={8000}>
                                <img className="slider-img" src={img1} alt="img1"></img>
                            </Carousel.Item>
                            <Carousel.Item interval={8000}>
                                <img className="slider-img" src={img2} alt="img2"></img>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className="laptop-bottom">
                        <div className="laptop-under"></div>
                    </div>
                    <div className="laptop-shadow"></div>
                </div>
            </div>
        </section>
    )
}

export default Controling